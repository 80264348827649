<template>
  <Header />
  <div class="middle-content">
    <router-view />
  </div>
  <!-- BUTTON TOP START -->
  <button class="scroltop">
    <span class="fa fa-angle-up relative" id="btn-vibrate"></span>
  </button>

  <Footer />
</template>

<script>
import Header from "../components/website/Header.vue";
import Footer from "../components/website/Footer.vue";
import "@/assets/template/css/style.css";
import "@/assets/template/css/mystyle.css";

import "@/assets/template/js/jquery-3.6.0.min.js"; // Import jQuery
import "@/assets/template/js/popper.min.js"; // Import Popper.js
import "@/assets/template/css/bootstrap.min.css";
import "@/assets/template/css/font-awesome.min.css";
import "@/assets/template/css/feather.css";
import "@/assets/template/css/owl.carousel.min.css";
import "@/assets/template/js/owl.carousel.min.js"; // Import Owl Carousel
// import "@/assets/template/css/magnific-popup.min.css";
// import "@/assets/template/css/lc_lightbox.css";
// import "@/assets/template/css/bootstrap-select.min.css";
// import "@/assets/template/css/dataTables.bootstrap5.min.css";
// import "@/assets/template/css/select.bootstrap5.min.css";
// import "@/assets/template/css/dropzone.css";
// import "@/assets/template/css/scrollbar.css";
// import "@/assets/template/css/datepicker.css";
import "@/assets/template/js/bootstrap.min.js"; // Import Bootstrap
import "@/assets/template/js/magnific-popup.min.js"; // Import Magnific Popup
import "@/assets/template/js/waypoints.min.js"; // Import Waypoints
import "@/assets/template/js/waypoints-sticky.min.js"; // Import Waypoints Sticky
// import '@/assets/template/js/counterup.min.js'; // Import CounterUp
// import "@/assets/template/js/isotope.pkgd.min.js"; // Import Isotope
import "@/assets/template/js/imagesloaded.pkgd.min.js"; // Import ImagesLoaded
import "@/assets/template/js/theia-sticky-sidebar.js"; // Import Theia Sticky Sidebar
// import '@/assets/template/js/lc_lightbox.lite.js'; // Import Lc Lightbox
// import '@/assets/template/js/bootstrap-select.min.js'; // Import Bootstrap Select
import "@/assets/template/js/dropzone.js"; // Import Dropzone
import "@/assets/template/js/jquery.scrollbar.js"; // Import Scrollbar
// import '@/assets/template/js/bootstrap-datepicker.js'; // Import Datepicker
// import '@/assets/template/js/jquery.dataTables.min.js'; // Import DataTables
// import '@/assets/template/js/dataTables.bootstrap5.min.js'; // Import DataTables Bootstrap 5
import "@/assets/template/js/chart.js"; // Import Chart.js
import "@/assets/template/js/custom.js"; // Import your custom JavaScript file

export default {
  components: { Header, Footer },
  methods: {},
};
</script>

<style scoped>
/* @import "@/assets/template/css/style.css";
@import "@/assets/template/css/mystyle.css"; */
</style>