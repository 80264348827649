<template>
  <footer class="footer-dark">
    <div class="container">
      <!-- NEWS LETTER SECTION START -->
      <!-- <div class="ftr-nw-content">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="ftr-nw-title">
                                Join our email subscription now to know more about our extraordinary mentors and get notified for discounted or even free sessions! 
                            </div>
                        </div>
                        <div class="col-md-7">
                            <form>
                                <div class="ftr-nw-form">
                                    <input name="news-letter" class="form-control" placeholder="Enter Your Email" type="text">
                                    <button class="ftr-nw-subcribe-btn">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->
      <!-- NEWS LETTER SECTION END -->
      <!-- FOOTER BLOCKES START -->
      <div class="footer-top">
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <div class="widget widget_about">
              <div class="logo-footer clearfix">
                <a href="index.html"
                  ><img src="../../assets/images/logos/MW-white.png" alt=""
                /></a>
              </div>
              <p>Be the Significant Change</p>
              <!-- <ul class="ftr-list"> -->
              <!-- <li><p><span>Address :</span>65 Sunset CA 90026, USA </p></li> -->
              <!-- <li><p><span>Email :</span>info@mentorwise.org</p></li> -->
              <!-- <li><p><span>Call :</span>555-555-1234</p></li> -->
              <!-- </ul> -->
            </div>
          </div>

          <div class="col-lg-9 col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget widget_services ftr-list-center">
                  <h3 class="widget-title">Quick Links</h3>
                  <ul>
                    <li><a href="index.html">Home</a></li>
                    <li><a href="about-1.html">About us</a></li>
                    <li><a href="dash-bookmark.html">Bookmark</a></li>
                    <li><a href="job-grid.html">Jobs</a></li>
                    <li><a href="coming-soon.html">Employer</a></li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="widget widget_services ftr-list-center">
                                        <h3 class="widget-title">For Candidate</h3>
                                        <ul>
                                            <li><a href="dashboard.html">User Dashboard</a></li>
                                            <li><a href="dash-resume-alert.html">Alert resume</a></li>
                                            <li><a href="candidate-grid.html">Candidates</a></li>
                                            <li><a href="blog-list.html">Blog List</a></li>
                                            <li><a href="blog-single.html">Blog single</a></li>
                                        </ul>
                                    </div>
                                </div> -->

              <div class="col-lg-9 col-md-12 col-sm-12">
                <div class="ftr-nw-content">
                  <form>
                    <div class="ftr-nw-form">
                      <input
                        name="news-letter"
                        class="form-control"
                        placeholder="Enter Your Email"
                        type="text"
                      />
                      <button class="ftr-nw-subcribe-btn">Subscribe Now</button>
                    </div>
                  </form>
                  <h3 class="footer-support">Quick Links</h3>
                  <ul class="ftr-list">
                    <!-- <li><p><span>Address :</span>65 Sunset CA 90026, USA </p></li> -->
                    <li>
                      <p><span>Email :</span>info@mentorwise.org</p>
                    </li>
                    <!-- <li><p><span>Call :</span>555-555-1234</p></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER COPYRIGHT -->
      <div class="footer-bottom">
        <div class="footer-bottom-info">
          <div class="footer-copy-right">
            <span class="copyrights-text"
              >Copyright © 2022 by ANALYTICA FZE.</span
            >
          </div>
          <ul class="social-icons">
            <li>
              <a href="javascript:void(0);" class="fab fa-facebook-f"></a>
            </li>
            <li><a href="javascript:void(0);" class="fab fa-twitter"></a></li>
            <li><a href="javascript:void(0);" class="fab fa-instagram"></a></li>
            <li><a href="javascript:void(0);" class="fab fa-youtube"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-dark {
  background-image: url("../../assets/template/images/f-bg.png");
}
a {
  text-decoration: none;
}
</style>