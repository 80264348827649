<template>
  <div class="page-content">
    <!--Banner Start-->
    <div
      class="twm-home1-banner-section site-bg-gray bg-cover"
      style="
        background-image: url(../../assets/template/images/main-slider/slider1/bg1.jpg);
      "
    >
      <div class="row">
        <!--Left Section-->
        <div class="col-xl-6 col-lg-6 col-md-12 pl-30">
          <div class="twm-bnr-left-section">
            <div class="twm-bnr-title-small">
              Be the <span class="site-text-primary">Significant</span> Change
            </div>
            <div class="twm-bnr-title-large">
              Find or become the next
              <a
                href=""
                class="typewrite"
                data-period="2000"
                data-type='[ "Advisor", "Consultant", "Mentor", "Mentee" ]'
              >
                <span class="wrap"></span>
              </a>
            </div>
            <div class="twm-bnr-discription">
              Type down your area of interest and get started!
            </div>

            <div class="twm-bnr-search-bar shadow">
              <form>
                <div class="row">
                  <!--Title-->
                  <div class="form-group col-xl-8 col-lg-8 col-md-8">
                    <div class="twm-single-iput">
                      <input
                        name="username"
                        type="text"
                        required=""
                        class="form-control bg-none"
                        placeholder="Try Marketing, JAVA Script, UI UX"
                      />
                    </div>
                  </div>

                  <!--Find job btn-->
                  <div class="form-group col-xl-4 col-lg-4 col-md-4">
                    <button type="button" class="site-button">Search</button>
                  </div>
                </div>
              </form>
            </div>

            <div class="twm-bnr-popular-search bg-clr-white shadow">
              <div class="widget tw-sidebar-tags-wrap">
                <div class="container popular-search">
                  <h4 class="section-head-small mb-3">Popular Searches</h4>

                  <div class="tagcloud">
                    <a href="job-list.html">JAVASCRIPT MENTORS</a>
                    <a href="job-list.html">PRODUCT MANAGERS </a>
                    <a href="job-list.html">PYTHON</a>
                    <a href="job-list.html">MARKETING MANAGERS </a>
                    <a href="job-list.html">UX DESIGN MENTORS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--right Section-->
        <div class="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
          <div class="twm-bnr-right-content">
            <div class="twm-img-bg-circle-area">
              <div class="twm-img-bg-circle1 rotate-center"><span></span></div>
              <div class="twm-img-bg-circle2 rotate-center-reverse">
                <span></span>
              </div>
              <div class="twm-img-bg-circle3"><span></span></div>
            </div>

            <div class="twm-bnr-right-carousel">
              <div class="owl-carousel twm-h1-bnr-carousal">
                <div class="item">
                  <div class="slide-img">
                    <img
                      src="../../assets/template/images/main-slider/slider1/r-img1.png"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="item">
                  <div class="slide-img">
                    <div class="slide-img">
                      <img
                        src="../../assets/template/images/main-slider/slider1/r-img2.png"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Samll Ring Left-->
            <div class="twm-small-ring-l slide-top-animation"></div>
            <div class="twm-small-ring-2 slide-top-animation"></div>
          </div>
        </div>
      </div>
    </div>
    <!--Banner End-->

    <!-- HOW IT WORK SECTION START -->
    <div class="section-full p-t120 p-b90 site-bg-white twm-how-it-work-area">
      <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
          <!-- <div class="wt-small-separator site-text-primary">
                           <div>Delete</div>                                
                        </div> -->
          <h2 class="wt-title">In Few Steps</h2>
        </div>
        <!-- TITLE END-->

        <div class="twm-how-it-work-section">
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="twm-w-process-steps">
                <!-- <span class="twm-large-number">01</span> -->
                <div class="twm-w-pro-top bg-clr-custom">
                  <div class="twm-media">
                    <span
                      ><img
                        src="../../assets/template/images/work-process/icon1.png"
                        alt="icon1"
                    /></span>
                  </div>
                  <h4 class="twm-title">Become a Mentor</h4>
                </div>
                <p>
                  Share your knowledge and experience by registering as a mentor
                  and uploading your documents to be verified. You can earn as
                  you go, volunteer for free and update your CV.
                </p>
              </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="twm-w-process-steps">
                <!-- <span class="twm-large-number">02</span> -->
                <div class="twm-w-pro-top bg-clr-pink">
                  <div class="twm-media">
                    <span
                      ><img
                        src="../../assets/template/images/work-process/icon3.png"
                        alt="icon1"
                    /></span>
                  </div>
                  <h4 class="twm-title">Register as a Mentee</h4>
                </div>
                <p>
                  Join MentorWise community and explore endless opportunities of
                  shared knowledge and guidance. Students registered will
                  receive special rated price or even sessions for free! Keep an
                  eye for Volunteering Mentors for all users.
                </p>
              </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="twm-w-process-steps">
                <!-- <span class="twm-large-number">03</span> -->
                <div class="twm-w-pro-top bg-clr-green">
                  <div class="twm-media">
                    <span
                      ><img
                        src="../../assets/template/images/work-process/icon2.png"
                        alt="icon1"
                    /></span>
                  </div>
                  <h4 class="twm-title">Update Your CV</h4>
                </div>
                <p>
                  Mentors and Mentees will both receive a Blockchain-based
                  certificate upon completion that can be added to LinkedIn in
                  just a click.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- HOW IT WORK SECTION END -->

    <!-- JOBS CATEGORIES SECTION START -->
    <div class="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area">
      <div class="container">
        <div class="wt-separator-two-part">
          <div class="row wt-separator-two-part-row">
            <div class="col-xl-5 col-lg-5 col-md-12 wt-separator-two-part-left">
              <!-- TITLE START-->
              <div class="section-head left wt-small-separator-outer">
                <div class="wt-small-separator site-text-primary">
                  <div>Jobs by Categories</div>
                </div>
                <h2 class="wt-title">Choose Your Desire Category</h2>
              </div>
              <!-- TITLE END-->
            </div>

            <div
              class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right"
            >
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the standard dummy text ever since the when an printer
                took.
              </p>
            </div>
          </div>
        </div>

        <div class="twm-job-categories-section">
          <div class="job-categories-style1 m-b30">
            <div
              class="owl-carousel job-categories-carousel owl-btn-left-bottom"
            >
              <!-- COLUMNS 1 -->
              <div class="item">
                <div class="job-categories-block">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-1.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">9,185 Mentors</div>
                    <a href="job-detail.html">Business Planning</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 2 -->
              <div class="item">
                <div class="job-categories-block">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-2.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">3,205 Mentors</div>
                    <a href="job-detail.html">Engineering Services</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 3 -->
              <div class="item">
                <div class="job-categories-block">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-3.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">2,100 Mentors</div>
                    <a href="job-detail.html">Industrial Project Development</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 4 -->
              <div class="item">
                <div class="job-categories-block">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-4.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">1,500 Mentors</div>
                    <a href="job-detail.html">Senior Design Project</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 5 -->
              <div class="item">
                <div class="job-categories-block">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-5.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">2,100 Mentors</div>
                    <a href="job-detail.html">Peer Tutor</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right job-categories-btn">
            <a href="job-grid.html" class="site-button">All Categories</a>
          </div>
        </div>
      </div>
    </div>
    <!-- JOBS CATEGORIES SECTION END -->

    <!-- JOB POST START -->
    <div
      class="section-full p-t120 p-b90 site-bg-light-custom twm-bg-ring-wrap"
    >
      <div class="twm-bg-ring-right"></div>
      <div class="twm-bg-ring-left"></div>
      <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
          <div class="wt-small-separator site-text-primary">
            <div>All Jobs Post</div>
          </div>
          <h2 class="wt-title">Find Your Career You Deserve it</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
          <div class="twm-jobs-list-wrap">
            <ul>
              <li>
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic1.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4>
                        Senior Web Designer , Developer
                        <span class="twm-job-post-duration">/ 1 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-green">New</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic2.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4>
                        Need Senior Rolling Stock Technician<span
                          class="twm-job-post-duration"
                          >/ 15 days ago</span
                        >
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-brown">Intership</span>
                    </div>
                    <div class="twm-jobs-amount">$2000<span>/ Month</span></div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic3.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        IT Department Manager & Blogger-Entrepenour
                        <span class="twm-job-post-duration">/ 6 Month ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-purple">Fulltime</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $1500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic4.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        Art Production Specialist
                        <span class="twm-job-post-duration">/ 2 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-sky">Freelancer</span>
                    </div>
                    <div class="twm-jobs-amount">$1200<span>/ Month</span></div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic5.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        Recreation & Fitness Worker
                        <span class="twm-job-post-duration">/ 1 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-golden">Temporary</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $1700 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>
            </ul>
            <div class="text-center m-b30">
              <a href="job-list.html" class="site-button">Browse All Jobs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- JOB POST END -->

    <!-- TESTIMONIAL SECTION START -->
    <div class="section-full p-t120 p-b90 site-bg-white twm-testimonial-1-area">
      <div class="container">
        <div class="wt-separator-two-part">
          <div class="row wt-separator-two-part-row">
            <div class="col-xl-5 col-lg-6 col-md-12 wt-separator-two-part-left">
              <!-- TITLE START-->
              <div class="section-head left wt-small-separator-outer">
                <div class="wt-small-separator site-text-primary">
                  <div>Clients Testimonials</div>
                </div>
                <h2 class="wt-title">What Our Customers Say About Us</h2>
              </div>
              <!-- TITLE END-->
            </div>
          </div>
        </div>

        <div class="section-content">
          <div
            class="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center"
          >
            <!-- COLUMNS 1 -->
            <div class="item">
              <div class="twm-testimonial-1">
                <div class="twm-testimonial-1-content">
                  <div class="twm-testi-media">
                    <img
                      src="../../assets/template/images/testimonials/pic-1.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-testi-content">
                    <div class="twm-quote">
                      <img
                        src="../../assets/template/images/quote-dark.png"
                        alt=""
                      />
                    </div>
                    <div class="twm-testi-info">
                      I just got a job that I applied for via careerfy! I used
                      the site all the time during my job hunt.
                    </div>
                    <div class="twm-testi-detail">
                      <div class="twm-testi-name">Nikola Tesla</div>
                      <div class="twm-testi-position">Accountant</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- COLUMNS 2 -->
            <div class="item">
              <div class="twm-testimonial-1">
                <div class="twm-testimonial-1-content">
                  <div class="twm-testi-media">
                    <img
                      src="../../assets/template/images/testimonials/pic-2.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-testi-content">
                    <div class="twm-quote">
                      <img
                        src="../../assets/template/images/quote-dark.png"
                        alt=""
                      />
                    </div>
                    <div class="twm-testi-info">
                      I just got a job that I applied for via careerfy! I used
                      the site all the time during my job hunt.
                    </div>
                    <div class="twm-testi-detail">
                      <div class="twm-testi-name">Nikola Tesla</div>
                      <div class="twm-testi-position">Accountant</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- COLUMNS 3 -->
            <div class="item">
              <div class="twm-testimonial-1">
                <div class="twm-testimonial-1-content">
                  <div class="twm-testi-media">
                    <img
                      src="../../assets/template/images/testimonials/pic-3.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-testi-content">
                    <div class="twm-quote">
                      <img
                        src="../../assets/template/images/quote-dark.png"
                        alt=""
                      />
                    </div>
                    <div class="twm-testi-info">
                      I just got a job that I applied for via careerfy! I used
                      the site all the time during my job hunt.
                    </div>
                    <div class="twm-testi-detail">
                      <div class="twm-testi-name">Nikola Tesla</div>
                      <div class="twm-testi-position">Accountant</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- COLUMNS 4 -->
            <div class="item">
              <div class="twm-testimonial-1">
                <div class="twm-testimonial-1-content">
                  <div class="twm-testi-media">
                    <img
                      src="../../assets/template/images/testimonials/pic-4.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-testi-content">
                    <div class="twm-quote">
                      <img
                        src="../../assets/template/images/quote-dark.png"
                        alt=""
                      />
                    </div>
                    <div class="twm-testi-info">
                      I just got a job that I applied for via careerfy! I used
                      the site all the time during my job hunt.
                    </div>
                    <div class="twm-testi-detail">
                      <div class="twm-testi-name">Nikola Tesla</div>
                      <div class="twm-testi-position">Accountant</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TESTIMONIAL SECTION END -->
    <!-- OUR BLOG START -->
    <div class="section-full p-t120 p-b90 site-bg-gray">
      <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
          <div class="wt-small-separator site-text-primary">
            <div>Our Blogs</div>
          </div>
          <h2 class="wt-title">Latest Article</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
          <div class="twm-blog-post-1-outer-wrap">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <!--Block one-->
                <div class="blog-post twm-blog-post-1-outer">
                  <div class="wt-post-media">
                    <a href="blog-single.html"
                      ><img
                        src="../../assets/template/images/blog/latest/bg1.jpg"
                        alt=""
                    /></a>
                  </div>
                  <div class="wt-post-info">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-date">March 05, 2022</li>
                        <li class="post-author">
                          By <a href="candidate-detail.html">Mark Petter</a>
                        </li>
                      </ul>
                    </div>
                    <div class="wt-post-title">
                      <h4 class="post-title">
                        <a href="blog-single.html"
                          >How to convince recruiters and get your dream job</a
                        >
                      </h4>
                    </div>
                    <div class="wt-post-text">
                      <p>
                        New chip traps clusters of migrating tumor cells
                        asperiortenetur, blanditiis odit.
                      </p>
                    </div>
                    <div class="wt-post-readmore">
                      <a
                        href="blog-single.html"
                        class="site-button-link site-text-primary"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <!--Block two-->
                <div class="blog-post twm-blog-post-1-outer">
                  <div class="wt-post-media">
                    <a href="blog-single.html"
                      ><img
                        src="../../assets/template/images/blog/latest/bg2.jpg"
                        alt=""
                    /></a>
                  </div>
                  <div class="wt-post-info">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-date">March 05, 2022</li>
                        <li class="post-author">
                          By <a href="candidate-detail.html">David Wish</a>
                        </li>
                      </ul>
                    </div>
                    <div class="wt-post-title">
                      <h4 class="post-title">
                        <a href="blog-single.html"
                          >5 things to know about the March 2022 jobs report</a
                        >
                      </h4>
                    </div>
                    <div class="wt-post-text">
                      <p>
                        New chip traps clusters of migrating tumor cells
                        asperiortenetur, blanditiis odit.
                      </p>
                    </div>
                    <div class="wt-post-readmore">
                      <a
                        href="blog-single.html"
                        class="site-button-link site-text-primary"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <!--Block three-->
                <div class="blog-post twm-blog-post-1-outer">
                  <div class="wt-post-media">
                    <a href="blog-single.html"
                      ><img
                        src="../../assets/template/images/blog/latest/bg3.jpg"
                        alt=""
                    /></a>
                  </div>
                  <div class="wt-post-info">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-date">March 05, 2022</li>
                        <li class="post-author">
                          By <a href="candidate-detail.html">Mike Doe</a>
                        </li>
                      </ul>
                    </div>
                    <div class="wt-post-title">
                      <h4 class="post-title">
                        <a href="blog-single.html"
                          >Job Board is the most important sector in the
                          world</a
                        >
                      </h4>
                    </div>
                    <div class="wt-post-text">
                      <p>
                        New chip traps clusters of migrating tumor cells
                        asperiortenetur, blanditiis odit.
                      </p>
                    </div>
                    <div class="wt-post-readmore">
                      <a
                        href="blog-single.html"
                        class="site-button-link site-text-primary"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OUR BLOG END -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    const TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };
    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    const initTxtType = () => {
      var elements = document.getElementsByClassName("typewrite");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-type");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
      document.body.appendChild(css);
    };

    onMounted(() => {
      initTxtType();
    });
  },
};
</script>


<style scoped>
a {
  text-decoration: none;
}
</style>