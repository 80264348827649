import {
  createRouter,
  createWebHistory
} from "vue-router";
import FullLayout from "../views/FullLayout.vue";
import Homepage from "../components/website/Homepage.vue";
import AboutUs from "../components/website/AboutUs.vue";
// import AboutUs from "../components/website/Mentors.vue";
import Contact from "../components/website/Contact.vue";
import Dashboard from "../components/Dashboard.vue";
import WebLayout from "../views/WebLayout.vue";
import Mentors from "../components/website/Mentors.vue";

const routes = [{
    path: "/dashboard",
    component: FullLayout,
    children: [{
        path: "",
        component: Dashboard,
      },
      {
        path: "about",
        component: () => import( /* webpackChunkName: "about" */ "../components/AboutView.vue"),
      },
    ],
  },
  {
    path: "/",
    component: WebLayout,
    children: [{
        path: "",
        component: Homepage,
      },
      {
        path: "/aboutus",
        component: AboutUs,
      },
      {
        path: "/mentors",
        component: Mentors,
      },
      {
        path: "/contact",
        component: Contact,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

// {
//   path: "/about",
//   name: "about",
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () =>
//     import(/* webpackChunkName: "about" */ "../components/AboutView.vue"),
// lazyloadingsyntax},