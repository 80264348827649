<template>
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <div
      class="wt-bnr-inr overlay-wraper bg-center"
      style="
        background-image: url(../../assets/template/images/main-slider/slider1/bg1.jpg);
      "
    >
      <div class="overlay-main site-bg-white opacity-01"></div>
      <div class="container">
        <div class="wt-bnr-inr-entry">
          <div class="banner-title-outer">
            <div class="banner-title-name">
              <h2 class="wt-title">About Us</h2>
            </div>
          </div>
          <!-- BREADCRUMB ROW -->

          <div>
            <ul class="wt-breadcrumb breadcrumb-style-2">
              <li><a href="index.html">Home</a></li>
              <li>About Us</li>
            </ul>
          </div>

          <!-- BREADCRUMB ROW END -->
        </div>
      </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- JOBS CATEGORIES SECTION START -->
    <div
      class="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area2"
    >
      <!-- TITLE START-->
      <div class="section-head center wt-small-separator-outer">
        <div class="wt-small-separator site-text-primary">
          <div>Categories</div>
        </div>
        <h2 class="wt-title">Choose Your Desire Category</h2>
      </div>
      <!-- TITLE END-->

      <div class="container">
        <div class="twm-job-categories-section-2 m-b30">
          <div class="job-categories-style1 m-b30">
            <div class="row">
              <!-- COLUMNS 1 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-1.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">9,185 Mentors</div>
                    <a href="job-grid.html">Business Development</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 2 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-2.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">3,205 Mentors</div>
                    <a href="job-grid.html">Project Management</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 3 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-3.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">2,100 Mentors</div>
                    <a href="job-grid.html">Content Writer</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 4 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-4.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">1,500 Mentors</div>
                    <a href="job-grid.html">Costomer Services</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 5 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-1.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">9,185 Mentors</div>
                    <a href="job-grid.html">Business Development</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 6 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-2.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">3,205 Mentors</div>
                    <a href="job-grid.html">Project Management</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 7 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-3.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">2,100 Mentors</div>
                    <a href="job-grid.html">Content Writer</a>
                  </div>
                </div>
              </div>

              <!-- COLUMNS 8 -->
              <div class="col-lg-3 col-md-6">
                <div class="job-categories-block-2 m-b30">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-categories/icon-4.png"
                      alt="#"
                    />
                  </div>
                  <div class="twm-content">
                    <div class="twm-jobs-available">1,500 Mentors</div>
                    <a href="job-grid.html">Costomer Services</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center job-categories-btn">
            <a href="job-grid.html" class="site-button">All Categories</a>
          </div>
        </div>
      </div>
    </div>
    <!-- JOBS CATEGORIES SECTION END -->

    <!-- HOW IT WORK SECTION START -->
    <div class="section-full p-t120 p-b90 site-bg-white twm-how-it-work-area2">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <!-- TITLE START-->
            <div class="section-head left wt-small-separator-outer">
              <div class="wt-small-separator site-text-primary">
                <div>How It Works</div>
              </div>
              <h2 class="wt-title">Follow our steps we will help you.</h2>
            </div>
            <ul class="description-list">
              <li>
                <i class="feather-check"></i>
                Trusted & Quality Job
              </li>
              <li>
                <i class="feather-check"></i>
                International Job
              </li>
              <li>
                <i class="feather-check"></i>
                No Extra Charge
              </li>
              <li>
                <i class="feather-check"></i>
                Top Companies
              </li>
            </ul>
            <!-- TITLE END-->
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="twm-w-process-steps-2-wrap">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="twm-w-process-steps-2">
                    <div
                      class="twm-w-pro-top bg-clr-sky-light bg-sky-light-shadow"
                    >
                      <span class="twm-large-number text-clr-sky">01</span>
                      <div class="twm-media">
                        <span
                          ><img
                            src="../../assets/template/images/work-process/icon1.png"
                            alt="icon1"
                        /></span>
                      </div>
                      <h4 class="twm-title">Register<br />Your Account</h4>
                      <p>
                        You need to create an account to find the best and
                        preferred job.
                      </p>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="twm-w-process-steps-2">
                                            <div class="twm-w-pro-top bg-clr-yellow-light bg-yellow-light-shadow">
                                                <span class="twm-large-number text-clr-yellow">02</span>
                                                <div class="twm-media">
                                                    <span><img src="images/work-process/icon4.png" alt="icon1"></span>
                                                </div>
                                                <h4 class="twm-title">Search <br>
                                                    Your Job</h4>                                                
                                                <p>You need to create an account to find the best and preferred job.</p>
                                            </div>
                                        
                                        </div>
                                    </div> -->

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="twm-w-process-steps-2">
                    <div
                      class="twm-w-pro-top bg-clr-pink-light bg-pink-light-shadow"
                    >
                      <span class="twm-large-number text-clr-pink">02</span>
                      <div class="twm-media">
                        <span
                          ><img
                            src="../../assets/template/images/work-process/icon3.png"
                            alt="icon1"
                        /></span>
                      </div>
                      <h4 class="twm-title">Apply <br />For Dream Job</h4>
                      <p>
                        You need to create an account to find the best and
                        preferred job.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="twm-w-process-steps-2">
                    <div
                      class="twm-w-pro-top bg-clr-green-light bg-clr-light-shadow"
                    >
                      <span class="twm-large-number text-clr-green">03</span>
                      <div class="twm-media">
                        <span
                          ><img
                            src="../../assets/template/images/work-process/icon3.png"
                            alt="icon1"
                        /></span>
                      </div>
                      <h4 class="twm-title">Upload <br />Your Resume</h4>
                      <p>
                        You need to create an account to find the best and
                        preferred job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="twm-how-it-work-section"></div>
      </div>
    </div>
    <!-- HOW IT WORK SECTION END -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>