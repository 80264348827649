<template>
  <header class="site-header header-style-3 mobile-sider-drawer-menu">
    <div class="sticky-header main-bar-wraper navbar-expand-lg shadow">
      <div class="main-bar">
        <div class="container-fluid clearfix">
          <div class="logo-header">
            <div class="logo-header-inner logo-header-one">
              <a href="/">
                <img
                  src="../../assets/images/logos/MV-color.png"
                  alt=""
                  class="h-logo"
                />
              </a>
            </div>
          </div>

          <!-- NAV Toggle Button -->
          <button
            id="mobile-side-drawer"
            data-target=".header-nav"
            data-toggle="collapse"
            type="button"
            class="navbar-toggler collapsed"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar icon-bar-first"></span>
            <span class="icon-bar icon-bar-two"></span>
            <span class="icon-bar icon-bar-three"></span>
          </button>

          <!-- MAIN Vav -->
          <div
            class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center"
          >
            <ul class="nav navbar-nav">
              <li class="has-child">
                <router-link to="/">Home</router-link>
              </li>
              <li class="has-child">
                <router-link to="/aboutus">About Us</router-link>
              </li>
              <li class="has-child">
                <router-link to="/mentors">Mentors</router-link>
              </li>
              <li class="has-child">
                <router-link to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </div>

          <!-- Header Right Section-->
          <div class="extra-nav header-2-nav">
            <div class="extra-cell">
              <div class="header-search">
                <a href="#search" class="header-search-icon"
                  ><i class="feather-search"></i
                ></a>
              </div>
            </div>
            <div class="extra-cell">
              <div class="header-nav-btn-section">
                <div class="twm-nav-btn-left">
                  <a
                    class="twm-nav-sign-up"
                    data-bs-toggle="modal"
                    href="#sign_up_popup"
                    role="button"
                  >
                    <i class="feather-log-in"></i> Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SITE Search -->
      <div id="search">
        <span class="close"></span>
        <form
          role="search"
          id="searchform"
          action="/search"
          method="get"
          class="radius-xl"
        >
          <input
            class="form-control"
            value=""
            name="q"
            type="search"
            placeholder="Type to search"
          />
          <span class="input-group-append">
            <button type="button" class="search-btn">
              <i class="fa fa-paper-plane"></i>
            </button>
          </span>
        </form>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>