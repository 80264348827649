<template>
  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <div
      class="wt-bnr-inr overlay-wraper bg-center"
      style="background-image: url(images/main-slider/slider1/bg1.jpg)"
    >
      <div class="overlay-main site-bg-white opacity-01"></div>
      <div class="container">
        <div class="wt-bnr-inr-entry">
          <div class="banner-title-outer">
            <div class="banner-title-name">
              <h2 class="wt-title">Contact Us</h2>
            </div>
          </div>
          <!-- BREADCRUMB ROW -->

          <div>
            <ul class="wt-breadcrumb breadcrumb-style-2">
              <li><a href="index.html">Home</a></li>
              <li>Contact Us</li>
            </ul>
          </div>

          <!-- BREADCRUMB ROW END -->
        </div>
      </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- CONTACT FORM -->
    <div class="section-full twm-contact-one">
      <div class="section-content">
        <div class="container">
          <!-- CONTACT FORM-->
          <div class="contact-one-inner">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="contact-form-outer">
                  <!-- TITLE START-->
                  <div class="section-head left wt-small-separator-outer">
                    <h2 class="wt-title">Feel Free to Reach Out</h2>
                    <p>
                      Feel free to contact us and we will get back to you as
                      soon as we can.
                    </p>
                  </div>
                  <!-- TITLE END-->

                  <form
                    class="cons-contact-form"
                    method="post"
                    action="form-handler2.php"
                  >
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                          <input
                            name="username"
                            type="text"
                            required
                            class="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                          <input
                            name="email"
                            type="text"
                            class="form-control"
                            required
                            placeholder="Email"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                          <input
                            name="phone"
                            type="text"
                            class="form-control"
                            required
                            placeholder="Phone"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                          <input
                            name="subject"
                            type="text"
                            class="form-control"
                            required
                            placeholder="Subject"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <textarea
                            name="message"
                            class="form-control"
                            rows="3"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <button type="submit" class="site-button">
                          Submit Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="contact-info-wrap">
                  <div class="contact-info">
                    <div class="contact-info-section">
                      <!-- <div class="c-info-column">
                                                        <div class="c-info-icon"><i class=" fas fa-map-marker-alt"></i></div>
                                                        <h3 class="twm-title">In the bay area?</h3>
                                                        <p>1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                    </div>   -->

                      <!-- <div class="c-info-column">
                                                        <div class="c-info-icon custome-size"><i class="fas fa-mobile-alt"></i></div>
                                                        <h3 class="twm-title">Feel free to contact us</h3>
                                                        <p><a href="tel:+216-761-8331">+971 4 336 6430</a></p>
                                                        <p><a href="tel:+216-761-8331">+2 900 234 3219</a></p>
                                                    </div> -->

                      <div class="c-info-column">
                        <div class="c-info-icon">
                          <i class="fas fa-envelope"></i>
                        </div>
                        <h3 class="twm-title">Support</h3>
                        <p>info@mentorwise.com</p>
                        <p>support@mentorwise.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->
</template>

<script>
export default {};
</script>

<style>
</style>